import Vue from  'vue';
import Turbolinks from 'turbolinks';
import TurbolinksAdapter from 'vue-turbolinks';

Vue.use(TurbolinksAdapter);

// This is a rewrite of Turbolink's requestLoaded function as recommended here:
// https://github.com/turbolinks/turbolinks/issues/179#issuecomment-289287888
// and the only known workaround to avoid JS crashing when rendering a page
// through TL with another status code than 2xx, which is obviously the case when
// we redirect to our custom error page subsequently to following a TL-enabled link.
Turbolinks.HttpRequest.prototype.requestLoaded = function () {
  return this.endRequest(function () {
    const code = this.xhr.status;
    if (200 <= code && code < 300 || [403, 404, 422, 500].includes(code)) {
      this.delegate.requestCompletedWithResponse(
        this.xhr.responseText,
        this.xhr.getResponseHeader('Turbolinks-Location'));
    } else {
      this.failed = true;
      this.delegate.requestFailedWithStatusCode(code, this.xhr.responseText);
    }
  }.bind(this));
};

export default Turbolinks;
